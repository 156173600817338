.slider {
  overflow: hidden;
  display: flex;
  justify-content: center;
  &-wrapper-sert {
    display: flex;
    gap: 10px;
  }
}

.wrapper-slider-sert {
  margin: 20px auto 80px auto;
  position: relative;
  max-width: 850px;
  height: 560px;
  overflow: hidden;
  padding-top: 60px;
}
.wrapper-slider-sert .outer{
  display: flex;
  align-items: center;
  gap: 20px;
  height: 350px;
  justify-content: center;
}

.wrapper-slider-sert .card-sert{
  background: #fff;
  width: 300px;
  display: flex;
  align-items: center;
  padding: 20px;
  opacity: 0;
  //pointer-events: none;
  position: absolute;
  justify-content: space-between;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 10px 30px rgba(115, 75, 75, 0.5);

  transition: 1s;
}

.card-sert {
  height: 425px;
  background: #decdc6 !important;
  box-shadow: 0 -3px #2f1c1e inset;
  transition: 0.2s;

  .content {
    font-size: 40px;
    color: white;
    display: flex;
    justify-content: space-between;
    .text {
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        float:left;
        margin-right: 10px;
        border-radius: 4px;
      }
      font-size: 14px;
      color: #2f1c1e;
    }
  }
}

.wrapper-slider-sert {
  .prev {
    border: none;
    background-color: transparent;
    cursor: pointer;
    img {
      transform: rotate(90deg);
    }
    position: absolute;
    top: 89%;
    left: 0;
    z-index: 10;
  }
  .next {
    border: none;
    background-color: transparent;
    cursor: pointer;
    img {
      transform: rotate(-90deg);
    }
    position: absolute;
    top: 89%;
    right: 0;
    z-index: 10;
  }
}
