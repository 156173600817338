.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: 1s;
  z-index: 60;

  &__wrapper {
    min-width: 500px;
    min-height: 200px;
    border-radius: 20px;
    transform: translateY(-50%);
    opacity: 0;
    transition: 1s;
    padding: 20px;
    background-color: #f6f6f6;
    @media (max-width: 768px) {
      min-width: 300px;
      min-height: 200px;
      h2{
        font-size: 22px;
        max-width: 250px;
      }
    }
    .btn-close{
      display: flex;
      margin-left: auto;
      border: none;
      background-color: #f6f6f6;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      input {
        margin-bottom: 20px;
        margin-left: 30px;
        width: 350px;
      }

      input[type=text] {
        width: 350px;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        @media (max-width: 768px) {
          padding: 12px 10px;
        }

        @media (max-width: 768px) {
          width: 250px;
        }
      }

      input[type=text]:focus {
        border: 2px solid #555;
      }
      input[type=number] {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        @media (max-width: 768px) {
          padding: 12px 10px;
        }
      }
      input[type=number]:focus {
        border: 2px solid #555;
      }
      textarea {
        width: 350px;
        height: 100px;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        resize: none;
        @media (max-width: 768px) {
          width: 250px;
        }
      }
      .submit {
        text-decoration: none;
        background-color: #decdc6;
        padding: 10px 12px;
        width: 180px; //??
        cursor: pointer;
        color: #2f1c1e;
        text-align: center;
        margin: 0 8px;  //потом убрать
        font-weight: 500;
        border-radius: 4px;
        box-shadow: 0 -3px #b19d95 inset;
        outline: none;
        border: none;
        transition: 0.2s;
      }
    }
  }

  &__active {
    opacity: 1;
    visibility: visible;

    .modal__wrapper {
      transform: translateY(50%);
      opacity: 1;
      padding-left: 30px;

      @media (max-width: 1200px) {
        transform: translateY(40%);
      }

      @media (max-width: 992px) {
        transform: translateY(30%);
      }

      @media (max-width: 767px) {
        transform: translateY(25%);
      }

      @media (max-width: 480px) {
        transform: translateY(20%);
      }

    }
  }
}
