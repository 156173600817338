// базовые подключения
@import "vars";
@import "mixins";
@import "fonts";
@import "settings";

// подключения компонентов страницы
@import "./components/header";
@import "./components/modal";
@import "./components/slider";
@import "./components/slider-sert";
@import "./components/footer";
@import "./components/policy";
@import "./components/oferta";
@import "lightgallery-bundle";



:root {
  --color-grey: #6f6f6f;
  --color-h2: #33302e;
  --color-btn: #282524;
  --color-text: #2f1c1e;
}

.wrapper {
  margin: 0 auto;
  max-width: 1400px;
}

body {
  color: var(--color-text);
  background-color: #f6f6f6;

  .section {
    max-width: 1400px;
    padding: 0 10px;
    padding: 50px 0;
    @media (max-width:768px) {
      padding: 20px 0;
    }
    h2 {
      margin: 0;
      padding: 0;
      font-size: 32px;
    }
  }
}

button {
  color: var(--color-btn);
}

main {
  margin-top: 120px !important;

  @media (max-width: 992px) {
    margin-top: 55px !important;
  }
}
.wrapper-1 {
  background-image: url("../img/fon-5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.section_work {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--color-h2);
  }
  &__line {
    width: 100px;
    border-top: 2px solid #decdc6;
    margin: 15px 0;
  }
  &__description {
    max-width: 600px;
    line-height: 1.7em;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  &__group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 290px;
      height: 360px;
      margin: 0 8px;
      @media (max-width: 768px) {
        height: 300px;
      }
      @media (max-width: 500px) {
        height: 220px;
      }
      img {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 15px;
        @media (max-width: 768px) {
          width: 180px;
          height: 180px;
        }
        @media (max-width: 500px) {
          width: 140px;
          height: 140px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.section_video {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  h2 {
    color: var(--color-h2);
  }
  &__description {
    margin-bottom: 30px;
    font-size: 24px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
      max-width: 80%;
      font-size: 20px;
    }
  }
  &__block_video {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .left {
      img {
        width: 500px;
        @media (max-width: 768px) {
          width: 270px;
        }
      }
    }
    .right {
      padding: 0 20px;
      text-align: justify;
      font-size: 18px;
      max-width: 60%;
      hyphens: auto;
      @media (max-width: 768px) {
        padding: 0;
        max-width: 90%;
      }
      .citat-one {
        margin: 10px 0;
        font-style: italic;
        max-width: 60%;
        text-align: right;
        margin-left: auto;
        margin-bottom: 50px;
        span {
          font-weight: 700;
        }
      }
      .name {
        margin: auto;
        text-align: center;
        margin-bottom: 10px;
      }

    }
  }
  &__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &_item {
      margin: 0 4px;
      width: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
      @media (max-width: 768px) {
        width: 300px;
        margin-bottom: 20px;
      }
      .line {
        width: 300px;
        border-top: 1px solid #2f1c1e;
        margin: 8px 0;
        @media (max-width: 768px) {
          width: 200px;
        }
      }
      .number {
        color: #2f1c1e;
        font-size: 40px;
        @media (max-width: 768px) {
          font-size: 36px;
        }
      }
    }
  }
}

.section_title {

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  .left {
    margin-left: 100px;
    @media (max-width: 768px) {
      margin-left: 0px;
    }
    .title {
      text-align: center;
      max-width: 600px;
      .name {
        font-size: 36px;
        font-family: 'Marck Script', cursive;
      }
      .logg {
        font-size: 24px;
        line-height: 30px;
        font-family: 'PT Sans Narrow', sans-serif;
      }

    }
  }
  .right {
    img {
      width: 500px;
      @media (max-width: 768px) {
        width: 300px;
      }
    }
  }
}

.section_for-you {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  h2 {
    color: var(--color-h2);
  }
  &__line {
    width: 100px;
    border-top: 2px solid #decdc6;
    margin: 20px 0;
  }
  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
    &_item {
      display: flex;
      align-items: center;
      margin: 0 15px;
      width: 350px;
      padding-bottom: 10px;
      @media (max-width: 768px) {
        width: 300px;
      }
      .icon {
        min-width: 80px;
        min-height: 80px;
        background-color: #decdc6;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        img {
          width: 50px;
          height: 50px;
        }
        @media (max-width: 768px) {
          min-width: 60px;
          min-height: 60px;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
      .text {
        text-align: start;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
}

.section_price {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  h2 {
    color: var(--color-h2);
  }
  &__line {
    width: 100px;
    border-top: 2px solid #decdc6;
    margin: 20px 0;
  }
  &__group {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &_item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 0 30px;
      width: 300px;
      height: 500px;
      border-radius: 4px;
      background-color: #decdc6;
      text-align: center;
      padding: 40px 10px;
      margin-top: 10px;
      @media (max-width: 1100px) {
        width: 200px;
        height: 360px;
        margin: 0 20px;
      }
      @media (max-width: 768px) {
        margin-bottom: 15px;
        height: 300px;
      }
      .text {
        font-size: 22px;
        span {
          font-size: 28px;
        }
        .descr {
          font-size: 16px;
          line-height: 30px;
          padding: 0 40px;
        }
        @media (max-width: 1100px) {
          font-size: 18px;
          span {
            font-size: 24px;
          }
          .descr {
            font-size: 12px;
            line-height: 20px;
            padding: 0 20px;
          }
        }
        @media (max-width: 768px) {
          .descr {
            padding: 0 15px;
          }
        }
      }

      .btn_send {
        text-decoration: none;
        background-color: #7b5d5f;
        padding: 8px 12px;
        width: 180px;
        cursor: pointer;
        color: #decdc6;
        text-align: center;
        margin: 0 8px;
        font-weight: 500;
        border-radius: 30px;
        box-shadow: 0 -3px #b19d95 inset;
        outline: none;
        border: none;
        transition: .4s;
        @media (max-width: 768px) {
          padding: 4px 8px;
          width: 140px;
          font-size: 12px;
        }
      }
      .btn_send:hover {
        background: #f6d5c6;
        color: #2f1c1e;
      }
    }
  }
}

.section_slider {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--color-h2);
  }

  &__line {
    width: 100px;
    border-top: 2px solid #decdc6;
    margin: 20px 0;
  }

  &__description {
    max-width: 600px;
    line-height: 1.7em;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
}

.section_sertificate {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--color-h2);
  }

  &__line {
    width: 100px;
    border-top: 2px solid #decdc6;
    margin: 15px 0;
    margin-bottom: 50px;
  }

  .diplom {
    img {
      width: 300px;
    }
    margin-bottom: 50px;
  }

}

.section-down {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
  .left {
    text-align: center;
    max-width: 500px;
    margin: 0 30px;
    .title {
      font-size: 26px;
      margin-bottom: 30px;
    }
    .icons {
      margin-bottom: 15px;
      a {
        cursor: pointer;
        margin: 0 8px;
      }
    }
    .block-number, .block-email {
      display: flex;
      margin-bottom: 15px;
      text-decoration: none;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      a {
        color: black;
      }
    }
  }
  .right {
    margin: 0 30px;
    img {
      width: 500px;
      @media (max-width: 992px) {
        width: 300px;
      }
    }
  }
}

.section-home_work {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  .left {
    img {
      width: 500px;
      @media (max-width: 992px) {
        width: 300px;
      }
    }
  }
  .right {
    padding: 0 20px;
    text-align: justify;
    font-size: 18px;
    max-width: 60%;
    hyphens: auto;
    @media (max-width: 992px) {
      padding: 0 8px;
      max-width: 60%;
      font-size: 14px;
    }
    @media (max-width: 992px) {
      padding: 8px;
      max-width: 90%;
      font-size: 14px;
    }
    .citat-two {
      margin: 10px 0;
      font-style: italic;
      max-width: 80%;
      text-align: right;
      margin-left: auto;
      margin-bottom: 10px;
      span {
        font-weight: 700;
      }
    }
  }
}
