.header {
  background-color: #decdc6;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 40;

  .menu_top {
    background-color: #7b5d5f;
    width: 100%;
    color: #f8f8f8;

    @media (max-width: 992px) {
      display: none;
    }
  }
  .menu_bottom {
    color: #2f1c1e;
    .wrapper {
      margin: auto;
      max-width: 1400px;
      .menu_bottom_items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 55px;

          .icon-logo {
            width: 50px;
          }
          .logo {
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-size: 28px;
            color: #2f1c1e;
            font-family: 'Pattaya', sans-serif;
            @media (max-width: 767px) {
              font-size: 22px;
            }
          }

        &__item {
          margin: 0 20px;
          cursor: pointer;
          color: #2f1c1e;
        }
        .nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 800px;

          @media (max-width: 992px) {
            display: none;
          }
        }
      }
    }
  }
}

.burger {
  .wrapper {
    @media (max-width: 992px) {
      height: 220px;
    }

    @media (max-width: 992px) {
      margin: 0;
    }
  }
}

.wrapper {
  margin: 0 auto;
  max-width: 1400px;

  .menu_top_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    padding: 0 4px;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;

      & > div {
        margin-bottom: 20px;
      }
    }
    .menu_top_items__item {
      a {
        color: #f8f8f8;
        text-decoration: underline;
        cursor:pointer;

        @media (max-width: 992px) {
          color: #2f1c1e;
        }
      }
    }
    .menu_top_items__item,
    .group_social {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      p {
        margin: 0;
        padding: 0;
      }
      .btn_send {
        text-decoration: none;
        background-color: #decdc6;
        padding: 10px 12px;
        width: 180px; //??
        cursor: pointer;
        color: #2f1c1e;
        text-align: center;
        margin: 0 8px;  //потом убрать
        font-weight: 500;
        border-radius: 4px;
        box-shadow: 0 -3px #b19d95 inset;
        outline: none;
        border: none;
        transition: 0.2s;

        @media (max-width: 1200px) {
          width: auto;
        }

        @media (max-width: 992px) {
          background: #ad948a;
        }

      }
      .btn_send:hover {
        background: #f6d5c6;
        transition: .3s;
      }
    }
    .group_social {
      width: 120px;
      img {
        cursor: pointer;
      }
    }
  }
}

.menu_bottom_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  .logo {
    padding: 0 10px;
    font-size: 20px;
    color: #2f1c1e;
  }
  &__item {
    margin: 0 20px;
    cursor: pointer;
    color: #2f1c1e;
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px;

    @media (max-width: 992px) {
      display: none;
    }
  }
}

.burger {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: 1s;
  z-index: 50;

  &__nav {
    & > div {
      margin-bottom: 25px;
      font-size: 20px;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    background: #decdc6;
    padding: 50px 10px 30px 30px;
    transform: translateX(-100%);
    transition: 1s;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    &.active {
      opacity: 1;
      pointer-events: auto;

      .burger__content {
        transform: translateX(0%);
      }
    }
  }

  &_btn {
    display: none;
    height: 16px;
    width: 30px;
    position: relative;
    margin-right: 15px;
    cursor: pointer;

    &_close {
      display: none;
      position: relative;
      z-index: 10;
      top: 14px;
      right: 15px;

      @media (max-width: 767px) {
        display: flex;
        height: 30px;
        width: 100%;
        cursor: pointer;
        justify-content: flex-end;

        span {
          position: absolute;
          right: 0;
          height: 2px;
          width: 30px;
          background: #2f1c1e;

          &:first-of-type {
            top: 13px;
            transform: rotate(45deg);
          }

          &:last-of-type {
            bottom: 14px;
            transform: rotate(-45deg);
          }
        }
      }
    }

    @media (max-width: 992px) {
      display: block;
    }

    span {
      position: absolute;
      left: 0;
      height: 2px;
      width: 100%;
      background: #2f1c1e;

      &:first-of-type {
        top: 0;
      }

      &:nth-child(2) {
        top: 7px;
      }

      &:last-of-type {
        bottom: 0;
      }
    }
  }
}
