.oferta {
  padding: 50px 0;
  line-height: 22px;
  &__title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  &__dogovor {
    font-size: 22px;
    font-weight: bold;
    margin: 20px 0;
  }
}