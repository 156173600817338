footer {
  background-color: #decdc6;
  .wrapper {
    padding: 20px;
    .section-footer {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      margin: 10px;
      line-height: 24px;
      .link {
        color: #2f1c1e;
        text-decoration: underline;
      }
    }
  }
}
