@charset "UTF-8";
:root {
  --font-family: "Open Sans", sans-serif;
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.header {
  background-color: #decdc6;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 40;
}
.header .menu_top {
  background-color: #7b5d5f;
  width: 100%;
  color: #f8f8f8;
}
@media (max-width: 992px) {
  .header .menu_top {
    display: none;
  }
}
.header .menu_bottom {
  color: #2f1c1e;
}
.header .menu_bottom .wrapper {
  margin: auto;
  max-width: 1400px;
}
.header .menu_bottom .wrapper .menu_bottom_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
}
.header .menu_bottom .wrapper .menu_bottom_items .icon-logo {
  width: 50px;
}
.header .menu_bottom .wrapper .menu_bottom_items .logo {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 28px;
  color: #2f1c1e;
  font-family: "Pattaya", sans-serif;
}
@media (max-width: 767px) {
  .header .menu_bottom .wrapper .menu_bottom_items .logo {
    font-size: 22px;
  }
}
.header .menu_bottom .wrapper .menu_bottom_items__item {
  margin: 0 20px;
  cursor: pointer;
  color: #2f1c1e;
}
.header .menu_bottom .wrapper .menu_bottom_items .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
}
@media (max-width: 992px) {
  .header .menu_bottom .wrapper .menu_bottom_items .nav {
    display: none;
  }
}

@media (max-width: 992px) {
  .burger .wrapper {
    height: 220px;
  }
}
@media (max-width: 992px) {
  .burger .wrapper {
    margin: 0;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: 1400px;
}
.wrapper .menu_top_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  padding: 0 4px;
}
@media (max-width: 992px) {
  .wrapper .menu_top_items {
    flex-direction: column;
    align-items: flex-start;
  }
  .wrapper .menu_top_items > div {
    margin-bottom: 20px;
  }
}
.wrapper .menu_top_items .menu_top_items__item a {
  color: #f8f8f8;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 992px) {
  .wrapper .menu_top_items .menu_top_items__item a {
    color: #2f1c1e;
  }
}
.wrapper .menu_top_items .menu_top_items__item,
.wrapper .menu_top_items .group_social {
  display: flex;
  align-items: center;
}
.wrapper .menu_top_items .menu_top_items__item img,
.wrapper .menu_top_items .group_social img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.wrapper .menu_top_items .menu_top_items__item p,
.wrapper .menu_top_items .group_social p {
  margin: 0;
  padding: 0;
}
.wrapper .menu_top_items .menu_top_items__item .btn_send,
.wrapper .menu_top_items .group_social .btn_send {
  text-decoration: none;
  background-color: #decdc6;
  padding: 10px 12px;
  width: 180px;
  cursor: pointer;
  color: #2f1c1e;
  text-align: center;
  margin: 0 8px;
  font-weight: 500;
  border-radius: 4px;
  box-shadow: 0 -3px #b19d95 inset;
  outline: none;
  border: none;
  transition: 0.2s;
}
@media (max-width: 1200px) {
  .wrapper .menu_top_items .menu_top_items__item .btn_send,
  .wrapper .menu_top_items .group_social .btn_send {
    width: auto;
  }
}
@media (max-width: 992px) {
  .wrapper .menu_top_items .menu_top_items__item .btn_send,
  .wrapper .menu_top_items .group_social .btn_send {
    background: #ad948a;
  }
}
.wrapper .menu_top_items .menu_top_items__item .btn_send:hover,
.wrapper .menu_top_items .group_social .btn_send:hover {
  background: #f6d5c6;
  transition: 0.3s;
}
.wrapper .menu_top_items .group_social {
  width: 120px;
}
.wrapper .menu_top_items .group_social img {
  cursor: pointer;
}

.menu_bottom_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
}
.menu_bottom_items .logo {
  padding: 0 10px;
  font-size: 20px;
  color: #2f1c1e;
}
.menu_bottom_items__item {
  margin: 0 20px;
  cursor: pointer;
  color: #2f1c1e;
}
.menu_bottom_items .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
}
@media (max-width: 992px) {
  .menu_bottom_items .nav {
    display: none;
  }
}

.burger {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: 1s;
  z-index: 50;
}
.burger__nav > div {
  margin-bottom: 25px;
  font-size: 20px;
}
.burger__content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  background: #decdc6;
  padding: 50px 10px 30px 30px;
  transform: translateX(-100%);
  transition: 1s;
}
@media (max-width: 767px) {
  .burger__content {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .burger.active {
    opacity: 1;
    pointer-events: auto;
  }
  .burger.active .burger__content {
    transform: translateX(0%);
  }
}
.burger_btn {
  display: none;
  height: 16px;
  width: 30px;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
}
.burger_btn_close {
  display: none;
  position: relative;
  z-index: 10;
  top: 14px;
  right: 15px;
}
@media (max-width: 767px) {
  .burger_btn_close {
    display: flex;
    height: 30px;
    width: 100%;
    cursor: pointer;
    justify-content: flex-end;
  }
  .burger_btn_close span {
    position: absolute;
    right: 0;
    height: 2px;
    width: 30px;
    background: #2f1c1e;
  }
  .burger_btn_close span:first-of-type {
    top: 13px;
    transform: rotate(45deg);
  }
  .burger_btn_close span:last-of-type {
    bottom: 14px;
    transform: rotate(-45deg);
  }
}
@media (max-width: 992px) {
  .burger_btn {
    display: block;
  }
}
.burger_btn span {
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background: #2f1c1e;
}
.burger_btn span:first-of-type {
  top: 0;
}
.burger_btn span:nth-child(2) {
  top: 7px;
}
.burger_btn span:last-of-type {
  bottom: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: 1s;
  z-index: 60;
}
.modal__wrapper {
  min-width: 500px;
  min-height: 200px;
  border-radius: 20px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 1s;
  padding: 20px;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .modal__wrapper {
    min-width: 300px;
    min-height: 200px;
  }
  .modal__wrapper h2 {
    font-size: 22px;
    max-width: 250px;
  }
}
.modal__wrapper .btn-close {
  display: flex;
  margin-left: auto;
  border: none;
  background-color: #f6f6f6;
  cursor: pointer;
}
.modal__wrapper .btn-close img {
  width: 20px;
  height: 20px;
}
.modal__wrapper form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.modal__wrapper form input {
  margin-bottom: 20px;
  margin-left: 30px;
  width: 350px;
}
.modal__wrapper form input[type=text] {
  width: 350px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .modal__wrapper form input[type=text] {
    padding: 12px 10px;
  }
}
@media (max-width: 768px) {
  .modal__wrapper form input[type=text] {
    width: 250px;
  }
}
.modal__wrapper form input[type=text]:focus {
  border: 2px solid #555;
}
.modal__wrapper form input[type=number] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .modal__wrapper form input[type=number] {
    padding: 12px 10px;
  }
}
.modal__wrapper form input[type=number]:focus {
  border: 2px solid #555;
}
.modal__wrapper form textarea {
  width: 350px;
  height: 100px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  resize: none;
}
@media (max-width: 768px) {
  .modal__wrapper form textarea {
    width: 250px;
  }
}
.modal__wrapper form .submit {
  text-decoration: none;
  background-color: #decdc6;
  padding: 10px 12px;
  width: 180px;
  cursor: pointer;
  color: #2f1c1e;
  text-align: center;
  margin: 0 8px;
  font-weight: 500;
  border-radius: 4px;
  box-shadow: 0 -3px #b19d95 inset;
  outline: none;
  border: none;
  transition: 0.2s;
}
.modal__active {
  opacity: 1;
  visibility: visible;
}
.modal__active .modal__wrapper {
  transform: translateY(50%);
  opacity: 1;
  padding-left: 30px;
}
@media (max-width: 1200px) {
  .modal__active .modal__wrapper {
    transform: translateY(40%);
  }
}
@media (max-width: 992px) {
  .modal__active .modal__wrapper {
    transform: translateY(30%);
  }
}
@media (max-width: 767px) {
  .modal__active .modal__wrapper {
    transform: translateY(25%);
  }
}
@media (max-width: 480px) {
  .modal__active .modal__wrapper {
    transform: translateY(20%);
  }
}

.slider {
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.slider-wrapper {
  display: flex;
  gap: 10px;
}

.wrapper-slider {
  margin: -20px 0 70px 0;
  position: relative;
  overflow: hidden;
  height: 610px;
  padding-top: 100px;
}

.wrapper-slider .outer {
  display: flex;
  align-items: center;
  height: 350px;
  justify-content: center;
}

.wrapper-slider .card {
  background: #fff;
  width: 500px;
  display: flex;
  align-items: start;
  padding: 20px;
  opacity: 0;
  position: absolute;
  justify-content: space-between;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 10px 30px rgba(115, 75, 75, 0.5);
  transition: 1s;
}
@media (max-width: 550px) {
  .wrapper-slider .card {
    width: calc(100% - 50px);
  }
}

.card {
  min-height: 500px;
  background: #decdc6 !important;
  box-shadow: 0 -3px #2f1c1e inset;
  transition: 0.2s;
}
.card .content {
  font-size: 40px;
  color: white;
  display: flex;
  justify-content: space-between;
}
.card .content .text {
  hyphens: auto;
  font-size: 14px;
  color: #2f1c1e;
}
.card .content .text img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  float: left;
  margin-right: 10px;
  border-radius: 4px;
}

.prev {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 92%;
  left: 0;
  z-index: 10;
}
.prev img {
  transform: rotate(90deg);
}

.next {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 92%;
  right: 0;
  z-index: 10;
}
.next img {
  transform: rotate(-90deg);
}

.slider {
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.slider-wrapper-sert {
  display: flex;
  gap: 10px;
}

.wrapper-slider-sert {
  margin: 20px auto 80px auto;
  position: relative;
  max-width: 850px;
  height: 560px;
  overflow: hidden;
  padding-top: 60px;
}

.wrapper-slider-sert .outer {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 350px;
  justify-content: center;
}

.wrapper-slider-sert .card-sert {
  background: #fff;
  width: 300px;
  display: flex;
  align-items: center;
  padding: 20px;
  opacity: 0;
  position: absolute;
  justify-content: space-between;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 10px 30px rgba(115, 75, 75, 0.5);
  transition: 1s;
}

.card-sert {
  height: 425px;
  background: #decdc6 !important;
  box-shadow: 0 -3px #2f1c1e inset;
  transition: 0.2s;
}
.card-sert .content {
  font-size: 40px;
  color: white;
  display: flex;
  justify-content: space-between;
}
.card-sert .content .text {
  font-size: 14px;
  color: #2f1c1e;
}
.card-sert .content .text img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  float: left;
  margin-right: 10px;
  border-radius: 4px;
}

.wrapper-slider-sert .prev {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 89%;
  left: 0;
  z-index: 10;
}
.wrapper-slider-sert .prev img {
  transform: rotate(90deg);
}
.wrapper-slider-sert .next {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 89%;
  right: 0;
  z-index: 10;
}
.wrapper-slider-sert .next img {
  transform: rotate(-90deg);
}

footer {
  background-color: #decdc6;
}
footer .wrapper {
  padding: 20px;
}
footer .wrapper .section-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 10px;
  line-height: 24px;
}
footer .wrapper .section-footer .link {
  color: #2f1c1e;
  text-decoration: underline;
}

.policy {
  padding: 50px 0;
}
.policy__title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.oferta {
  padding: 50px 0;
  line-height: 22px;
}
.oferta__title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
.oferta__dogovor {
  font-size: 22px;
  font-weight: bold;
  margin: 20px 0;
}

@font-face {
  font-family: "lg";
  src: url("../fonts/lg.woff2?io9a6k") format("woff2"), url("../fonts/lg.ttf?io9a6k") format("truetype"), url("../fonts/lg.woff?io9a6k") format("woff"), url("../fonts/lg.svg?io9a6k#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.lg-next,
.lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1084;
  outline: none;
  border: none;
}

.lg-next.disabled,
.lg-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}

.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
  color: #fff;
}

.lg-single-item .lg-next, .lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}

.lg-next:before {
  content: "ᐳ";
}

.lg-prev {
  left: 20px;
}

.lg-prev:after {
  content: "ᐸ";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  background: none;
  border: none;
  box-shadow: none;
}

.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "✖";
}

.lg-toolbar .lg-maximize {
  font-size: 22px;
}

.lg-toolbar .lg-maximize:after {
  content: "\e90a";
}

.lg-toolbar .lg-download:after {
  content: "⇓";
}

.lg-sub-html {
  color: #eee;
  font-size: 16px;
  padding: 10px 40px;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

.lg-sub-html a {
  color: inherit;
}

.lg-sub-html a:hover {
  text-decoration: underline;
}

.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}

.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-error-msg {
  font-size: 14px;
  color: #999;
}

.lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  height: 47px;
  vertical-align: middle;
}

.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next,
.lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -moz-transition: -moz-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -o-transition: -o-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  -moz-transform: scale3d(0.5, 0.5, 0.5);
  -o-transform: scale3d(0.5, 0.5, 0.5);
  -ms-transform: scale3d(0.5, 0.5, 0.5);
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed rgba(255, 255, 255, 0.6);
}

.lg-toolbar .lg-icon:focus-visible {
  border-radius: 8px;
  outline-offset: -5px;
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  width: 100%;
  max-height: 350px;
  overflow: hidden;
  float: left;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer .lg-thumb-outer.lg-rebuilding-thumbnails .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  text-align: center;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-left {
  text-align: left;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-right {
  text-align: right;
}

.lg-outer.lg-single-item .lg-thumb-outer {
  display: none;
}

.lg-outer .lg-thumb {
  padding: 5px 0;
  height: 100%;
  margin-bottom: -5px;
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .lg-outer .lg-thumb {
    padding: 10px 0;
  }
}
.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border-radius: 2px;
  margin-bottom: 5px;
  will-change: border-color;
}

@media (min-width: 768px) {
  .lg-outer .lg-thumb-item {
    border-radius: 4px;
    border: 2px solid #fff;
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}

.lg-outer.lg-animate-thumb .lg-thumb {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.lg-outer .lg-video-cont {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.lg-outer .lg-video-cont .lg-object {
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-has-iframe .lg-video-cont {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.lg-outer .lg-video-object {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.lg-outer .lg-video-poster {
  z-index: 1;
}

.lg-outer .lg-has-video .lg-video-object {
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-poster,
.lg-outer .lg-has-video.lg-video-loaded .lg-video-play-button {
  opacity: 0 !important;
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-object {
  opacity: 1;
}

@keyframes lg-play-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes lg-play-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lg-video-play-button {
  width: 18%;
  max-width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  cursor: pointer;
  transform: translate(-50%, -50%) scale(1);
  will-change: opacity, transform;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  -o-transition: -o-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  transition: transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
}

.lg-video-play-button:hover .lg-video-play-icon-bg,
.lg-video-play-button:hover .lg-video-play-icon {
  opacity: 1;
}

.lg-video-play-icon-bg {
  fill: none;
  stroke-width: 3%;
  stroke: #fcfcfc;
  opacity: 0.6;
  will-change: opacity;
  -webkit-transition: opacity 0.12s ease-in;
  -o-transition: opacity 0.12s ease-in;
  transition: opacity 0.12s ease-in;
}

.lg-video-play-icon-circle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  fill: none;
  stroke-width: 3%;
  stroke: rgba(30, 30, 30, 0.9);
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
}

.lg-video-play-icon {
  position: absolute;
  width: 25%;
  max-width: 120px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.6;
  will-change: opacity;
  -webkit-transition: opacity 0.12s ease-in;
  -o-transition: opacity 0.12s ease-in;
  transition: opacity 0.12s ease-in;
}

.lg-video-play-icon .lg-video-play-icon-inner {
  fill: #fcfcfc;
}

.lg-video-loading .lg-video-play-icon-circle {
  animation: lg-play-rotate 2s linear 0.25s infinite, lg-play-stroke 1.5s ease-in-out 0.25s infinite;
}

.lg-video-loaded .lg-video-play-button {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-single-item .lg-autoplay-button {
  opacity: 0.75;
  pointer-events: none;
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0ms !important;
  transition-duration: 0ms !important;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  -moz-transition: -moz-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  -o-transition: -o-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  transition: transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
}

.lg-outer.lg-use-transition-for-zoom.lg-zoom-drag-transition .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  transition: transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.no-transition,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.no-transition {
  transition: none !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition {
  transform: scale3d(1, 1, 1) translate3d(-50%, -50%, 0px) !important;
  max-width: none !important;
  max-height: none !important;
  top: 50% !important;
  left: 50% !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-x,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-x {
  transform: scale3d(1, 1, 1) translate3d(-50%, 0, 0px) !important;
  top: 0 !important;
  left: 50% !important;
  max-width: none !important;
  max-height: none !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-y,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-y {
  transform: scale3d(1, 1, 1) translate3d(0, -50%, 0px) !important;
  top: 50% !important;
  left: 0% !important;
  max-width: none !important;
  max-height: none !important;
}

.lg-icon.lg-zoom-in:after {
  content: "🔍";
}

.lg-actual-size .lg-icon.lg-zoom-in {
  opacity: 0.5;
  pointer-events: none;
}

.lg-icon.lg-actual-size {
  font-size: 20px;
}

.lg-icon.lg-actual-size:after {
  content: "\e033";
}

.lg-icon.lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

.lg-icon.lg-zoom-out:after {
  content: "\e312";
}

.lg-zoomed .lg-icon.lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer[data-lg-slide-type=video] .lg-zoom-in,
.lg-outer[data-lg-slide-type=video] .lg-actual-size,
.lg-outer[data-lg-slide-type=video] .lg-zoom-out, .lg-outer[data-lg-slide-type=iframe] .lg-zoom-in,
.lg-outer[data-lg-slide-type=iframe] .lg-actual-size,
.lg-outer[data-lg-slide-type=iframe] .lg-zoom-out, .lg-outer.lg-first-slide-loading .lg-zoom-in,
.lg-outer.lg-first-slide-loading .lg-actual-size,
.lg-outer.lg-first-slide-loading .lg-zoom-out {
  opacity: 0.75;
  pointer-events: none;
}

.lg-outer .lg-pager-outer {
  text-align: center;
  z-index: 1080;
  height: 10px;
  margin-bottom: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer.lg-single-item .lg-pager-outer {
  display: none;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #fff;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  will-change: transform, opacity;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer .lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, opacity;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active .lg-dropdown,
.lg-outer.lg-dropdown-active .lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer.lg-dropdown-active .lg-share {
  color: #fff;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  will-change: visibility, opacity, transform;
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}

.lg-outer .lg-dropdown > li:hover a {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer .lg-share {
  position: relative;
}

.lg-outer .lg-share:after {
  content: "\e80d";
}

.lg-outer .lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer .lg-share-facebook .lg-icon:after {
  content: "\e904";
}

.lg-outer .lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer .lg-share-twitter .lg-icon:after {
  content: "\e907";
}

.lg-outer .lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer .lg-share-pinterest .lg-icon:after {
  content: "\e906";
}

.lg-comment-box {
  width: 420px;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  will-change: transform;
  -moz-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-comment-box .lg-comment-title {
  margin: 0;
  color: #fff;
  font-size: 18px;
}

.lg-comment-box .lg-comment-header {
  background-color: #000;
  padding: 12px 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.lg-comment-box .lg-comment-body {
  height: 100% !important;
  padding-top: 43px !important;
  width: 100% !important;
}

.lg-comment-box .fb-comments {
  height: 100%;
  width: 100%;
  background: url("../images/loading.gif") no-repeat scroll center center #fff;
  overflow-y: auto;
  display: inline-block;
}

.lg-comment-box .fb-comments[fb-xfbml-state=rendered] {
  background-image: none;
}

.lg-comment-box .fb-comments > span {
  max-width: 100%;
}

.lg-comment-box .lg-comment-close {
  position: absolute;
  right: 5px;
  top: 12px;
  cursor: pointer;
  font-size: 20px;
  color: #999;
  will-change: color;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.lg-comment-box .lg-comment-close:hover {
  color: #fff;
}

.lg-comment-box .lg-comment-close:after {
  content: "\e070";
}

.lg-comment-box iframe {
  max-width: 100% !important;
  width: 100% !important;
}

.lg-comment-box #disqus_thread {
  padding: 0 20px;
}

.lg-outer .lg-comment-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, opacity;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer .lg-comment-toggle:after {
  content: "\e908";
}

.lg-outer.lg-comment-active .lg-comment-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer.lg-comment-active .lg-comment-toggle {
  color: #fff;
}

.lg-outer.lg-comment-active .lg-comment-box {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-img-rotate {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer[data-lg-slide-type=video] .lg-rotate-left,
.lg-outer[data-lg-slide-type=video] .lg-rotate-right,
.lg-outer[data-lg-slide-type=video] .lg-flip-ver,
.lg-outer[data-lg-slide-type=video] .lg-flip-hor, .lg-outer[data-lg-slide-type=iframe] .lg-rotate-left,
.lg-outer[data-lg-slide-type=iframe] .lg-rotate-right,
.lg-outer[data-lg-slide-type=iframe] .lg-flip-ver,
.lg-outer[data-lg-slide-type=iframe] .lg-flip-hor {
  opacity: 0.75;
  pointer-events: none;
}

.lg-outer .lg-img-rotate:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.lg-rotate-left:after {
  content: "\e900";
}

.lg-rotate-right:after {
  content: "fsd";
}

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px;
}

.lg-flip-ver:after {
  content: "fdfsdf03";
}

.lg-flip-hor:after {
  content: "sd02";
}

.lg-medium-zoom-item {
  cursor: zoom-in;
}

.lg-medium-zoom .lg-outer {
  cursor: zoom-out;
}

.lg-medium-zoom .lg-outer.lg-grab img.lg-object {
  cursor: zoom-out;
}

.lg-medium-zoom .lg-outer.lg-grabbing img.lg-object {
  cursor: zoom-out;
}

.lg-relative-caption .lg-outer .lg-sub-html {
  white-space: normal;
  bottom: auto;
  padding: 0;
  background-image: none;
}

.lg-relative-caption .lg-outer .lg-relative-caption-item {
  opacity: 0;
  padding: 16px 0;
  transition: 0.5s opacity ease;
}

.lg-relative-caption .lg-outer .lg-show-caption .lg-relative-caption-item {
  opacity: 1;
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-container {
  display: none;
  outline: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer {
  opacity: 1;
}

.lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}

.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  overflow: hidden;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-zoom-from-image {
  opacity: 1;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: opacity 0s;
  -o-transition: opacity 0s;
  transition: opacity 0s;
  white-space: nowrap;
}

.lg-outer .lg-item {
  display: none !important;
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}

.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
}

.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}

.lg-outer.lg-hide-download .lg-download {
  opacity: 0.75;
  pointer-events: none;
}

.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
  opacity: 1;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  will-change: auto;
  -webkit-transition: opacity 333ms ease-in 0s;
  -o-transition: opacity 333ms ease-in 0s;
  transition: opacity 333ms ease-in 0s;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
  display: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-container.lg-dragging-vertical .lg-backdrop {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
  position: absolute;
}

.lg-inline .lg-backdrop {
  z-index: 1;
}

.lg-inline .lg-outer {
  z-index: 2;
}

.lg-inline .lg-maximize:after {
  content: "ff";
}

.lg-components {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.35s ease-out 0s;
  -moz-transition: -moz-transform 0.35s ease-out 0s;
  -o-transition: -o-transform 0.35s ease-out 0s;
  transition: transform 0.35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

:root {
  --color-grey: #6f6f6f;
  --color-h2: #33302e;
  --color-btn: #282524;
  --color-text: #2f1c1e;
}

.wrapper {
  margin: 0 auto;
  max-width: 1400px;
}

body {
  color: var(--color-text);
  background-color: #f6f6f6;
}
body .section {
  max-width: 1400px;
  padding: 0 10px;
  padding: 50px 0;
}
@media (max-width: 768px) {
  body .section {
    padding: 20px 0;
  }
}
body .section h2 {
  margin: 0;
  padding: 0;
  font-size: 32px;
}

button {
  color: var(--color-btn);
}

main {
  margin-top: 120px !important;
}
@media (max-width: 992px) {
  main {
    margin-top: 55px !important;
  }
}

.wrapper-1 {
  background-image: url("../img/fon-5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.section_work {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.section_work h2 {
  color: var(--color-h2);
}
.section_work__line {
  width: 100px;
  border-top: 2px solid #decdc6;
  margin: 15px 0;
}
.section_work__description {
  max-width: 600px;
  line-height: 1.7em;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.section_work__group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.section_work__group_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  height: 360px;
  margin: 0 8px;
}
@media (max-width: 768px) {
  .section_work__group_item {
    height: 300px;
  }
}
@media (max-width: 500px) {
  .section_work__group_item {
    height: 220px;
  }
}
.section_work__group_item img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .section_work__group_item img {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 500px) {
  .section_work__group_item img {
    width: 140px;
    height: 140px;
    margin-bottom: 10px;
  }
}

.section_video {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.section_video h2 {
  color: var(--color-h2);
}
.section_video__description {
  margin-bottom: 30px;
  font-size: 24px;
}
@media (max-width: 768px) {
  .section_video__description {
    margin-bottom: 15px;
    max-width: 80%;
    font-size: 20px;
  }
}
.section_video__block_video {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .section_video__block_video {
    flex-direction: column;
  }
}
.section_video__block_video .left img {
  width: 500px;
}
@media (max-width: 768px) {
  .section_video__block_video .left img {
    width: 270px;
  }
}
.section_video__block_video .right {
  padding: 0 20px;
  text-align: justify;
  font-size: 18px;
  max-width: 60%;
  hyphens: auto;
}
@media (max-width: 768px) {
  .section_video__block_video .right {
    padding: 0;
    max-width: 90%;
  }
}
.section_video__block_video .right .citat-one {
  margin: 10px 0;
  font-style: italic;
  max-width: 60%;
  text-align: right;
  margin-left: auto;
  margin-bottom: 50px;
}
.section_video__block_video .right .citat-one span {
  font-weight: 700;
}
.section_video__block_video .right .name {
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
}
.section_video__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
  .section_video__group {
    flex-direction: column;
  }
}
.section_video__group_item {
  margin: 0 4px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 992px) {
  .section_video__group_item {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .section_video__group_item {
    width: 300px;
    margin-bottom: 20px;
  }
}
.section_video__group_item .line {
  width: 300px;
  border-top: 1px solid #2f1c1e;
  margin: 8px 0;
}
@media (max-width: 768px) {
  .section_video__group_item .line {
    width: 200px;
  }
}
.section_video__group_item .number {
  color: #2f1c1e;
  font-size: 40px;
}
@media (max-width: 768px) {
  .section_video__group_item .number {
    font-size: 36px;
  }
}

.section_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .section_title {
    flex-direction: column-reverse;
  }
}
.section_title .left {
  margin-left: 100px;
}
@media (max-width: 768px) {
  .section_title .left {
    margin-left: 0px;
  }
}
.section_title .left .title {
  text-align: center;
  max-width: 600px;
}
.section_title .left .title .name {
  font-size: 36px;
  font-family: "Marck Script", cursive;
}
.section_title .left .title .logg {
  font-size: 24px;
  line-height: 30px;
  font-family: "PT Sans Narrow", sans-serif;
}
.section_title .right img {
  width: 500px;
}
@media (max-width: 768px) {
  .section_title .right img {
    width: 300px;
  }
}

.section_for-you {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.section_for-you h2 {
  color: var(--color-h2);
}
.section_for-you__line {
  width: 100px;
  border-top: 2px solid #decdc6;
  margin: 20px 0;
}
.section_for-you__group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
@media (max-width: 1200px) {
  .section_for-you__group {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .section_for-you__group {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
.section_for-you__group_item {
  display: flex;
  align-items: center;
  margin: 0 15px;
  width: 350px;
  padding-bottom: 10px;
}
@media (max-width: 768px) {
  .section_for-you__group_item {
    width: 300px;
  }
}
.section_for-you__group_item .icon {
  min-width: 80px;
  min-height: 80px;
  background-color: #decdc6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.section_for-you__group_item .icon img {
  width: 50px;
  height: 50px;
}
@media (max-width: 768px) {
  .section_for-you__group_item .icon {
    min-width: 60px;
    min-height: 60px;
  }
  .section_for-you__group_item .icon img {
    width: 30px;
    height: 30px;
  }
}
.section_for-you__group_item .text {
  text-align: start;
}
@media (max-width: 768px) {
  .section_for-you__group_item .text {
    font-size: 14px;
  }
}

.section_price {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.section_price h2 {
  color: var(--color-h2);
}
.section_price__line {
  width: 100px;
  border-top: 2px solid #decdc6;
  margin: 20px 0;
}
.section_price__group {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .section_price__group {
    flex-direction: column;
  }
}
.section_price__group_item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
  width: 300px;
  height: 500px;
  border-radius: 4px;
  background-color: #decdc6;
  text-align: center;
  padding: 40px 10px;
  margin-top: 10px;
}
@media (max-width: 1100px) {
  .section_price__group_item {
    width: 200px;
    height: 360px;
    margin: 0 20px;
  }
}
@media (max-width: 768px) {
  .section_price__group_item {
    margin-bottom: 15px;
    height: 300px;
  }
}
.section_price__group_item .text {
  font-size: 22px;
}
.section_price__group_item .text span {
  font-size: 28px;
}
.section_price__group_item .text .descr {
  font-size: 16px;
  line-height: 30px;
  padding: 0 40px;
}
@media (max-width: 1100px) {
  .section_price__group_item .text {
    font-size: 18px;
  }
  .section_price__group_item .text span {
    font-size: 24px;
  }
  .section_price__group_item .text .descr {
    font-size: 12px;
    line-height: 20px;
    padding: 0 20px;
  }
}
@media (max-width: 768px) {
  .section_price__group_item .text .descr {
    padding: 0 15px;
  }
}
.section_price__group_item .btn_send {
  text-decoration: none;
  background-color: #7b5d5f;
  padding: 8px 12px;
  width: 180px;
  cursor: pointer;
  color: #decdc6;
  text-align: center;
  margin: 0 8px;
  font-weight: 500;
  border-radius: 30px;
  box-shadow: 0 -3px #b19d95 inset;
  outline: none;
  border: none;
  transition: 0.4s;
}
@media (max-width: 768px) {
  .section_price__group_item .btn_send {
    padding: 4px 8px;
    width: 140px;
    font-size: 12px;
  }
}
.section_price__group_item .btn_send:hover {
  background: #f6d5c6;
  color: #2f1c1e;
}

.section_slider {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.section_slider h2 {
  color: var(--color-h2);
}
.section_slider__line {
  width: 100px;
  border-top: 2px solid #decdc6;
  margin: 20px 0;
}
.section_slider__description {
  max-width: 600px;
  line-height: 1.7em;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.section_sertificate {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.section_sertificate h2 {
  color: var(--color-h2);
}
.section_sertificate__line {
  width: 100px;
  border-top: 2px solid #decdc6;
  margin: 15px 0;
  margin-bottom: 50px;
}
.section_sertificate .diplom {
  margin-bottom: 50px;
}
.section_sertificate .diplom img {
  width: 300px;
}

.section-down {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .section-down {
    flex-direction: column-reverse;
  }
}
.section-down .left {
  text-align: center;
  max-width: 500px;
  margin: 0 30px;
}
.section-down .left .title {
  font-size: 26px;
  margin-bottom: 30px;
}
.section-down .left .icons {
  margin-bottom: 15px;
}
.section-down .left .icons a {
  cursor: pointer;
  margin: 0 8px;
}
.section-down .left .block-number, .section-down .left .block-email {
  display: flex;
  margin-bottom: 15px;
  text-decoration: none;
  justify-content: center;
}
.section-down .left .block-number img, .section-down .left .block-email img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.section-down .left .block-number a, .section-down .left .block-email a {
  color: black;
}
.section-down .right {
  margin: 0 30px;
}
.section-down .right img {
  width: 500px;
}
@media (max-width: 992px) {
  .section-down .right img {
    width: 300px;
  }
}

.section-home_work {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .section-home_work {
    flex-direction: column;
  }
}
.section-home_work .left img {
  width: 500px;
}
@media (max-width: 992px) {
  .section-home_work .left img {
    width: 300px;
  }
}
.section-home_work .right {
  padding: 0 20px;
  text-align: justify;
  font-size: 18px;
  max-width: 60%;
  hyphens: auto;
}
@media (max-width: 992px) {
  .section-home_work .right {
    padding: 0 8px;
    max-width: 60%;
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .section-home_work .right {
    padding: 8px;
    max-width: 90%;
    font-size: 14px;
  }
}
.section-home_work .right .citat-two {
  margin: 10px 0;
  font-style: italic;
  max-width: 80%;
  text-align: right;
  margin-left: auto;
  margin-bottom: 10px;
}
.section-home_work .right .citat-two span {
  font-weight: 700;
}