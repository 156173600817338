.slider {
  overflow: hidden;
  display: flex;
  justify-content: center;

  &-wrapper {
    display: flex;
    gap: 10px;
  }
}

.wrapper-slider {
  margin: -20px 0 70px 0;
  position: relative;
  overflow: hidden;
  height: 610px;
  padding-top: 100px;

}
.wrapper-slider .outer{
  display: flex;
  align-items: center;
  height: 350px;
  justify-content: center;
}

.wrapper-slider .card{
  background: #fff;
  width: 500px;
  display: flex;
  align-items: start;
  padding: 20px;
  opacity: 0;
  //pointer-events: none;
  position: absolute;
  justify-content: space-between;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 10px 30px rgba(115, 75, 75, 0.5);
  transition: 1s;

  @media (max-width: 550px) {
    width: calc(100% - 50px);
  }
}

.card {
  min-height: 500px;
  background: #decdc6 !important;
  box-shadow: 0 -3px #2f1c1e inset;
  transition: 0.2s;
  .content {
    font-size: 40px;
    color: white;
    display: flex;
    justify-content: space-between;
    .text {
      hyphens: auto;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        float:left;
        margin-right: 10px;
        border-radius: 4px;
      }
      font-size: 14px;
      color: #2f1c1e;
    }
  }
}

.prev {
  border: none;
  background-color: transparent;
  cursor: pointer;
  img {
    transform: rotate(90deg);
  }
  position: absolute;
  top: 92%;
  left: 0;
  z-index: 10;

}
.next {
  border: none;
  background-color: transparent;
  cursor: pointer;
  img {
    transform: rotate(-90deg);
  }
  position: absolute;
  top: 92%;
  right: 0;
  z-index: 10;

}
